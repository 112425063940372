/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import {
  motion,
  AnimateSharedLayout,
  AnimatePresence,
  useInvertedScale,
} from 'framer-motion';

import logo from './logo.svg';
import Static from './StaticComponent';
import './App.css';
import { FiLinkedin, FiGithub } from 'react-icons/fi';
import { HiOutlineMail } from 'react-icons/hi';
import styled from 'styled-components';
import { ImArrowLeft2 } from 'react-icons/im';
import { Element, scroller, animateScroll as scroll } from 'react-scroll';
import ReactMarkdown from 'react-markdown/with-html';
import history from 'history/browser';
import { createGlobalState } from 'react-hooks-global-state';
import { useLocation } from './use-location';
import ModalImage from "react-modal-image";

const initialState = { page: parseInt(window.location.pathname[1]) };
const { useGlobalState } = createGlobalState(initialState);

const UnderLine = React.memo(function UnderLine() {
return (
  <AbsoluteSvg
    className="underline"
    xmlns="http://www.w3.org/2000/svg"
    width="313"
    height="8"
  >
    <motion.g
      transition={{ type: 'spring', delay: 1.4, duration: 0.8, bounce: 0.5 }}
      style={{ translateZ: 0 }}
      initial={{ x: -500, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 200, opacity: 0 }}
      >
        <g>
          <defs>
            <linearGradient
              id="idcgGdF9Sn5g2423952"
              gradientTransform="rotate(180, 0.5, 0.5)"
            >
              <stop offset="0" stopColor="#49B1F3" stopOpacity="1"></stop>
              <stop offset="1" stopColor="#47D8BC" stopOpacity="1"></stop>
            </linearGradient>
          </defs>
          <path
            d="M 122.866 -0.113 L 224.836 -0.113 L 224.836 3.887 L 224.836 7.887 L 122.866 7.887 Z"
            fill="url(#idcgGdF9Sn5g2423952)"
          ></path>
        </g>
        <g>
          <defs>
            <linearGradient
              id="idkAdEuXPw6g2424260"
              gradientTransform="rotate(0, 0.5, 0.5)"
            >
              <stop offset="0" stopColor="#49B1F3" stopOpacity="1"></stop>
              <stop offset="1" stopColor="#47D8BC" stopOpacity="1"></stop>
            </linearGradient>
          </defs>
          <path
            d="M 21.866 0.113 L 123.836 0.113 L 123.836 4.113 L 123.836 8.113 L 21.866 8.113 Z"
            fill="url(#idkAdEuXPw6g2424260)"
          ></path>
        </g>
        <g>
          <defs>
            <linearGradient
              id="idcgGdF9Sn5g2523952"
              gradientTransform="rotate(180, 0.5, 0.5)"
            >
              <stop offset="0" stopColor="#49B1F3" stopOpacity="1"></stop>
              <stop offset="1" stopColor="#47D8BC" stopOpacity="1"></stop>
            </linearGradient>
          </defs>
          <motion.path
            d="M -0.134 -0.113 L 101.836 -0.113 L 101.836 3.887 L 101.836 7.887 L -0.134 7.887 Z"
            fill="url(#idcgGdF9Sn5g2523952)"
          ></motion.path>
        </g>
        <g>
          <defs>
            <linearGradient
              id="idz4nNxXwlAg178012800"
              gradientTransform="rotate(180, 0.5, 0.5)"
            >
              <stop offset="0" stopColor="#8284F8" stopOpacity="1"></stop>
              <stop offset="1" stopColor="#49B1F3" stopOpacity="1"></stop>
            </linearGradient>
          </defs>
          <path
            d="M 100.116 0.113 L 202.086 0.113 L 202.086 4.113 L 202.086 8.113 L 100.116 8.113 Z"
            fill="url(#idz4nNxXwlAg178012800)"
          ></path>
        </g>
        <g>
          <defs>
            <linearGradient
              id="idw7OZ0EXDhg852579809"
              gradientTransform="rotate(180, 0.5, 0.5)"
            >
              <stop offset="0" stopColor="#B64FEA" stopOpacity="1"></stop>
              <stop offset="1" stopColor="#8284F8" stopOpacity="1"></stop>
            </linearGradient>
          </defs>
          <path
            d="M 201.883 -0.113 L 313.307 -0.113 L 313.307 3.887 L 313.307 7.887 L 201.883 7.887 Z"
            fill="url(#idw7OZ0EXDhg852579809)"
          ></path>
        </g>{' '}
      </motion.g>
    </AbsoluteSvg>
  );
});
const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    delay: 0.25,
    transition: {
      staggerChildren: 1,
      delayChildren: 0.2,
    },
  },
};

const itemVar = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
};

const AbsoluteSvg = styled.svg`
  position: absolute;
  left: -5px;
  top: 76%;
  z-index: -1;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3undefined 1fr);
  grid-template-rows: 6rem 1fr;
  grid-gap: var(--gutter);
  width: var(--wrapper);
  margin: auto;
  padding-top: 5rem;
  & > * {
    overflow-x: hidden;
  }
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 7rem 1fr;
    width: 95%;
  }
`;
const A = styled.a`
  color: #008cff;
  text-decoration: none;
`;
const H2 = motion.custom(styled.h2`
  font-family: 'IBM Plex Serif', serif;
  color: var(--black);
  font-size: 25px;
  letter-spacing: 0px;
  margin: 0;
  line-height: 1.2;
  font-weight: 500;
`);
const DarkCover = motion.custom(styled.div`
  z-index: 10;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
`);

const MainContent = motion.custom(styled.div`
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.62);
  background: radial-gradient(
    85% 100% at 54% 57.8%,
    #ffffff 0%,
    hsl(0, 33%, 96%) 100%
  );
  border-radius: 21px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`);
const MainContentText = motion.custom(styled.div`
  width: 75%;
  margin: auto;
  padding-top: 64px;
  padding-bottom: 64px;
  font-family: 'IBM Plex Serif', serif;
  color: var(--black);
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 1.9;
  font-weight: 500;
  @media only screen and (max-width: 768px) {
    padding-top: 24px;
    font-size: 14px;
    width: 82%;
  }
`);

const CenterFlex = motion.custom(styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`);
const ItemTitleText = motion.custom(styled.div`
  z-index: 11;
  font-family: 'IBM Plex Serif', serif;
  color: #ffffff;
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 1.2;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0.2em;
  border-radius: 10px;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
`);

const H3 = styled.h3`
  font-family: 'IBM Plex Serif', serif;
  color: var(--grey);
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 1.4;
  font-weight: 500;
  font-style: normal;
`;
const MottoText = motion.custom(styled.h1`
  color: var(--black);
  font-family: 'IBM Plex Serif', serif;
  font-weight: 500;
  font-size: 2.2em;
  @media only screen and (max-width: 768px) {
    font-size: 1.7em;
  }
  @media only screen and (max-width: 768px) {
    font-size: 1.4em;
  }
  margin: 0;
`);
const Title = styled.h1`
  color: var(--black);
  font-family: 'IBM Plex Serif', serif;
  font-size: 48px;
  @media only screen and (max-width: 768px) {
    font-size: 28px;
  }
  font-weight: 400;
  margin: 0;
  position: relative;
`;
const LogoFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: space-between;
  width: 100%;
  height: 100%;
  @media only screen and (max-width: 768px) {
    justify-content: center;
    max-width: 280px;
    margin: auto;
  }
`;
const NavFlex = styled.div`
  display: flex;
  align-items: baseline;
  font-family: 'IBM Plex Serif', serif;

  font-size: 25px;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }

  justify-content: flex-end;
  margin-top: -20px;
  margin-left: -20px;
  flex-wrap: wrap;
  align-content: space-around;
  & > * {
    margin-top: 20px;
    margin-left: 20px;
    color: var(--black);
    text-decoration: none;
  }
`;
const BackArrow = motion.custom(ImArrowLeft2);
const Bullet = styled.p`
  flex-basis: 0%;
  flex-grow: 1;
  width: 20px;
  height: 20px;
`;
const Header = styled.div`
  grid-area: 1 / 1 / 2 / 4;
  @media only screen and (max-width: 768px) {
    grid-area: 1 / 1 / 2 / 2;
  }
  @media only screen and (max-width: 768px) and (min-width: 768px) {
    grid-area: 1 / 1 / 2 / 4;
  }
`;

const Motto = styled.div`
  grid-area: 2 / 1 / 3 / 3;
  @media only screen and (max-width: 768px) {
    grid-area: 3 / 1 / 4 / 2;
    margin-top: 10%;
    margin-bottom: 13%;
  }
`;
const AvatarFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-content: space-around;
`;
const IconFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20vw;
  max-height: 250px;
  margin-right: 40px;
  a {
    color: var(--black);
  }
`;
const LinkedinIcon = styled(FiLinkedin)`
  flex-basis: 0%;
  flex-grow: 1;
  width: 35px;
  height: 35px;
`;
const GithubIcon = styled(FiGithub)`
  flex-basis: 0%;
  flex-grow: 1;
  width: 35px;
  height: 35px;
`;
const MailIcon = styled(HiOutlineMail)`
  flex-basis: 0%;
  flex-grow: 1;
  width: 35px;
  height: 35px;
`;

const ModalBox = motion.custom(styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`);
const ModalBoxLeft = motion.custom(styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`);
const ModalBoxExpanded = motion.custom(styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`);
const ModalBoxRight = motion.custom(styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`);

const ModalComp = motion.custom(styled.div`
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    url('https://albertajewishnews.com/wp-content/uploads/2020/02/ordinateur-de-bureau-pc-1456070535WEH.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.85);
  width: 80%;
  height: 80%;
  margin: 3px;
`);
const MottoFlex = motion.custom(styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-content: flex-start;
  height: 100%;
  @media only screen and (max-width: 768px) {
    align-items: center;
  }
`);
const Avatar = styled.div`
  grid-area: 2 / 3 / 3 / 4;
  overflow: visible;
  @media only screen and (max-width: 768px) {
    display: none;
  }

  @media only screen and (max-width: 768px) and (min-width: 768px) {
    grid-area: 3 / 3 / 3 / 4;
  }
`;

const AvaImg = styled.img`
  height: 20vw;
  max-height: 250px;
  width: auto;
  border-radius: 21px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.45);
`;
const Title2 = styled.div`
  grid-area: 5 / 1 / 6 / 4;
`;

const Title1 = motion.custom(styled.div`
  grid-area: 1 / 1 / 4 / 25;
`);
const TitleFlex = motion.custom(styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  max-width: min(500px, 90vw);
`);

const ExpandedItem = motion.custom(styled.div`
  grid-area: 3 / 1 / 5 / 4;
`);

const ModalGridArea = motion.custom(styled.div`
  display: grid;
  gap: 0;
  width: var(--wrapper);
  margin: auto;
  padding-top: 64px;
  grid-template-columns: repeat(var(--noOfColumns), 1fr);
  grid-template-rows: repeat(var(--noOfColumns), var(--rowHeight));
  margin-bottom: 2rem;
  & > * {
    overflow: hidden;
  }
  @media only screen and (max-width: 768px) {
    grid-template-rows: repeat(54, var(--rowHeight));
    padding-top: 16px;
  }
`);

const Item21 = styled.div`
  grid-area: 6 / 1 / 7 / 2;
`;

const Item22 = styled.div`
  grid-area: 6 / 2 / 7 / 3;
`;

const Item23 = styled.div`
  grid-area: 6 / 3 / 7 / 4;
`;
const Item1 = motion.custom(styled.div`
  grid-area: 5 / 1 / 11 / 7;
  border-radius: 10px;
`);
const Item2 = motion.custom(styled.div`
  grid-area: 5 / 10 / 11 / 16;
`);

const Item3 = motion.custom(styled.div`
  grid-area: 5 / 19 / 11 / 25;
`);
const StaticEval = function () {
  return false;
};
interface sizeState {
  width?: Number;
  height?: Number;
}
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  } as sizeState);
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      } as sizeState);
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
const renderers = {
  //This custom renderer changes how images are rendered
  //we use it to constrain the max width of an image to its container
  image: ({
    alt,
    src,
    title,
  }: {
    alt?: string;
    src?: string;
    title?: string;
  }) => (
<ModalImage
  small={src}
  large={src}
  alt={title}
/>
    // <img
    //   alt={alt}
    //   src={src}
    //   title={title}
    //   style={{
    //     width: '80%',
    //     display: 'block',
    //     margin: 'auto',
    //     borderRadius: '50px',
    //     marginTop: '5%',
    //   }}
    // />
  ),
};

const TopOfSite = React.memo(function TopOfSite() {
  const [page, setPage] = useGlobalState('page');
  return (
    <>
      <Header>
        <LogoFlex>
          <Title>
            Ajeet Kokatay
            <UnderLine></UnderLine>
          </Title>
          <NavFlex>
            <a
              style={{cursor: 'pointer'}}
              onClick={(e) => {
                e.preventDefault();
                setPage(-1);
                history.push('/');
              }}
            >
              home
            </a>
            <Bullet>•</Bullet>
            <a href="mailto:ajeetkokatay@gmail.com">contact</a>
            <Bullet>•</Bullet>
            <a href="/AjeetKokatayResume.pdf">resume</a>
          </NavFlex>
        </LogoFlex>
      </Header>
      <Motto>
        <MottoFlex variants={container} initial="hidden" animate="show">
          <MottoText variants={itemVar}>Designer.</MottoText>
          <MottoText variants={itemVar}>Full stack web developer.</MottoText>
        </MottoFlex>
      </Motto>
      <Avatar>
        <AvatarFlex>
          <IconFlex>
            <motion.a
              whileHover={{
                scale: 1.2,
                transition: { duration: 1 },
              }}
              href="https://www.linkedin.com/in/ajeet-kokatay-25470b195/"
            >
              <LinkedinIcon />
            </motion.a>
            <motion.a
              whileHover={{
                scale: 1.2,
                transition: { duration: 1 },
              }}
              href="https://github.com/codejeet"
            >
              <GithubIcon />
            </motion.a>
            <motion.a
              whileHover={{
                scale: 1.2,
                transition: { duration: 1 },
              }}
              href="mailto:ajeetkokatay@gmail.com"
            >
              <MailIcon />
            </motion.a>
          </IconFlex>
          <AvaImg src="/images/ajeet.webp"></AvaImg>
        </AvatarFlex>
      </Avatar>
    </>
  );
}, StaticEval);

const projects: modalItem[] = [
  {
    title: 'Citrus Hack 2021',
    content: `
### What I did: 

I created the official site for CitrusHack 2021, University of California Riverside's biggest annual hackathon. 

### How I did it:

#### Frontend Tech

* **Next.JS** for static rendering with Vercel hosting
* **Redux** for action based state management
* **OGL** for webgl mouse trails
* **Framer-Motion** for interactive animations
* **tsParticles** for the leaf particles
* **Material-UI** for the the application form and group UI components

#### Backend Tech

* **Strapi** for quickly creating API endpoints and easily implementing OAuth
* **Mailchimp** for adding registered emails to a mailing list.
* **DigitalOcean** for hosting the backend - more info in the CutieHack post

### Where to find it:

[https://citrushack.com](https://citrushackdemo.vercel.app/)

### UI samples:

![CitrusHack](/images/citrus1.png)
![CitrusHack](/images/citrus2.png)

### Front Page Screenshot

![CitrusHack](/images/citrusscreen.webp)
`,
    image:
      '/images/citrus.webp',
  },
  {
    title: 'Cutie Hack 2020',
    content: `
### What I did: 

I created the site that hundreds of hackers used to apply to Cutie Hack 2020, a beginner focused hackathon. In it users can apply, upload their resume, and generate a unique group code to send to their friends.

### How I did it:

#### Frontend Tech

* **React** for its functional DOM
* **Typescript** for autocomplete and safety
* **Redux** for action based state management
* **Redux-Saga** for invoking asynchrounous actions like fetching data from the backend
* **Framer-Motion** for animations and easter eggs

#### Backend Tech

* **Strapi** for quickly creating API endpoints and easily implementing OAuth
* **DigitalOcean** for hosting the web app.
  * **Nginx** to serve requests (both frontend and backend).
  * **PM2** to ensure the node process that Strapi uses stays up.
  * **CertBot** for generating self signed certificates.

### What I learned:

I learned how to create a production level full stack by myself in a little under two months. I was the only one working on the project so I learned a lot about time management.

### Where to find it:

[https://cutiehack.io](https://cutiehack-2020-demo.vercel.app/)

### Front Page Screenshot

![CutieHack](/images/cutiescreen.webp)


`,
    image:
      '/images/oranges.webp',
  },
  {
    title: 'BioHack 2021',
    content: `
### What I did: 

I created the backend API for BioHack 2021, University of California Riverside's annual Bio-engineering focused hackathon. Using Node.JS, hackers can apply for the hackathon, send in their resume, and sign up for email blasts with Mailchimp. Within the progressive web app, they can create a unique group code so that they can sign up with their friends.

### Where to find it:

[biohackucr.com](https://biohack-2021-demo.vercel.app/)

### Front Page Screenshot

![CutieHack](/images/biohackscreen.webp)


`,
    image:
      '/images/biohackthumb.webp',
  },

  {
    title: 'ACM@UCR Web Dev Seminar',
    content: `
### What I did: 

I hosted a weekly web development workshop for ACM at the University of California, Riverside.

### How I did it:

I gathered course material from around the internet and compiled them into easy to digest slideshows and some markdown files. 

### What I learned:

You don't learn something until you try to teach it in your own words. I already knew how to write the markup abstractly, but learning to articulate my understanding really brought me to the next level.

### Where to find it:
[Github Repo](https://github.com/acm-ucr/WebDev-Focus-Group)


[Google Slides](https://drive.google.com/drive/folders/1PhX9tjxqPTr76-j0quu0H085EGcs9mwM?usp=sharing)

### Example Slides

![Slide Example](/images/exampleslide1.webp)
![Slide Example](/images/exampleslide2.webp)

`,
    image:
      '/images/laptop.webp',
  },
  {
    title: 'Spidr Dev',
    content: `
### What I did: 

I co-founded a small web development firm, creating websites for local clients. 

### How I did it:

My general web development process:
1. Find a client
2. Create a UX-focused wireframe, focused on layout. 
3. Generate many copies of the wireframe with many styles
4. Repeat 2 until I'm happy
5. Turn the visual design into markup or react code.

### What I learned:

Through Spidr Dev I've learned the majority of my design and web development chops. I've gone through the process of wireframing, designing, and coding so many times that it's second nature at this point.

### Where to find it:
[spidr.dev](https://spidr.dev/)
`,
    image:
      '/images/spidr.webp',
  },
];
const casestudies: modalItem[] = [
  {
    title: 'Richard Horvitz',
    content: `
### Case Study
Richard Horvitz, voice actor on Invader Zim, The Grim Adventures of Billy and Mandy, Power Rangers, and more, approached Spidr to update his personal website. Spidr designed a website that resembles Richard’s quirky and bright personality. The homepage pops as it includes bright colors and a looping gif that contains Richard’s work. A news section allows users to follow Richard and remain up to date on his work and appearances. 

![Richard Horvitz](https://www.spidr.dev/media/horvitz-capture.jpg)

### Where to find it:
[richardhorvitz.com](https://richardhorvitz.com/)  
`,
    image: 'https://www.spidr.dev/media/zim-banner.jpg',
  },
  {
    title: 'The Bridge Agency',
    content: `
###  A website for the the Bridge Agency, a talent recruitment company. 
Spidr was approached by two experienced talent agents to create the website for their new business venture. This particular site is responsible for displaying company information and available jobs for users to apply to. A bold full page video immediately grabs the user’s eye, and a consistent color scheme and grid layout were design choices that boost the user's experience. The project was done with HTML, CSS, and React JS.

![The Bridge](https://www.spidr.dev/media/bridge.jpg)

### Where to find it:
[thebridgeagents.com](https://thebridgeagents.com/)  

`,
    image: 'https://www.spidr.dev/media/bridge-banner.jpg',
  },
  {
    title: 'Calm Naturelle',
    content: `
### Creating Calm Naturelle, a one stop shop for relaxation.  
Calm Naturelle is the result of a joint venture between Spidr's founders. They sought to sharpen their marketing and e-commerce skills by fabricating a brand from the ground up. The key design objective was to create a relaxing atmosphere that is still effective at generating sales. In addition to creating an online shop, they created a robust social media presence with thousands of likes in the span of a month.

![Calm Naturelle](https://www.spidr.dev/media/calm.png)
`,
    image: 'https://www.spidr.dev/media/beach.jpg',
  },
];
interface modalItem {
  title: string;
  content: string;
  image: string;
}
const ModalArea = function ModalArea() {

  const [pageHookVal, setPage] = useGlobalState('page');
  const loc = useLocation();
  var init;
  var browserloc = parseInt(window.location.pathname[1]);
  console.log(browserloc);
  if (browserloc !== pageHookVal) {
    init = browserloc;
  } else {
    init = pageHookVal;
  }
  return (
    <AnimateSharedLayout>
      <ModalFunction casestudies={casestudies} projects={projects} init={init} />
    </AnimateSharedLayout>
  );
};
const ModalFunction: React.FC<Props> = props => {
  const [pageHookVal, setPage] = useGlobalState('page');

  const [prevScrollPos, setPrevScrollPos] = useState(0);

  const size = useWindowSize();
  const isMobile: boolean | undefined = size.width && size.width < 768;
  const getGridAreaString = (i: number, offset = 0): String => {
    if(i > 2) return `9 / ${1 + (i-3) * 5} / 6 / ${4 + (i-3) * 5}`;
    return `${offset + 2} / ${1 + i * 5} / ${5 + offset} / ${4 + i * 5}`;
  }
  const getGridAreaStringMobile = (i: number, offset = 0): String => {
    return `${offset + (3 + i * 6)} / ${1} / ${offset + (8 + i * 6)} / ${14}`;
  };
  const currentGridAreaFunction: Function = isMobile
    ? getGridAreaStringMobile
    : getGridAreaString;
  const itemOffsetMob = 9 + (props.projects.length - 1) * 6;
  const itemOffsetDesk = 8;
  const itemOffset = isMobile ? itemOffsetMob : itemOffsetDesk;
  const executeScroll = () => {
    scroll.scrollTo(prevScrollPos, {
      duration: 1000,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };
  
  
//window.addEventListener("hashchange", goBack, false)
  const executeScrollUp = () => {
    scroller.scrollTo('scrollAnchorTop', {
      duration: 1000,
      delay: 0,
      offset: isMobile ? 0 : -50,
      smooth: 'easeInOutQuart',
    });
  };
  var page;
  if (!(props.init < (props.projects.length + props.casestudies.length) && props.init >= 0)) {
    page = -1;
  } else {
    page = props.init;
  }
  
  const projectItems = props.projects.map(function (item: modalItem, i) {
    return (
      <>
        <motion.div
          style={{ gridArea: currentGridAreaFunction(i, 0) }}
          layout
          layoutId={`content${i}`}
        ></motion.div>
        <motion.div
          style={{
            gridArea: currentGridAreaFunction(i, 0),
            zIndex: -11,
          }}
          layout
          key={`projPictureArea${i}`}
          layoutId={`projPictureArea${i}`}
          transition={{ type: 'spring', duration: 0.6, bounce: 0.3 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Element name={`scrollAnchor${i}`}></Element>
          <motion.img
            src={item.image}
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '10px',
              position: 'relative',
              zIndex: -10,
              objectFit: 'cover',
            }}
            // initial={{ opacity: 0 }}
            // animate={{ opacity: 1 }}
            // exit={{ opacity: 0 }}
            layout
            key={`projPictureImg${i}`}
            layoutId={`projPictureImg${i}`}
            transition={{ type: 'spring', duration: 0.6, bounce: 0.3 }}
          ></motion.img>
        </motion.div>
        <DarkCover
          transition={{ type: 'spring', duration: 0.6, bounce: 0.3 }}
          layoutId={`projTitle${i}`}
          key={`projTitle${i}`}
          layout
          onClick={() => {
            setPrevScrollPos(window.pageYOffset);
            history.push(`/${i}`);
            setPage(i);
            executeScrollUp();
          }}
          style={{
            gridArea: currentGridAreaFunction(i, 0),
            backgroundColor: isMobile ? 'rgba(0,0,0,0.75)' : 'rgba(0,0,0,0.69)',
          }}
        >
          <ItemTitleText>{item.title}</ItemTitleText>
        </DarkCover>
      </>
    );
  });
  const caseItems = props.casestudies.map(function (item: modalItem, i) {
    const shiftedIdx = i + props.projects.length;
    const caseOffset = itemOffset + 1;
    return (
      <>
        <motion.div
          style={{ gridArea: currentGridAreaFunction(i, caseOffset) }}
          layout
          layoutId={`content${shiftedIdx}`}
        ></motion.div>
        <motion.div
          style={{
            gridArea: currentGridAreaFunction(i, caseOffset),
            zIndex: -11,
          }}
          layout
          key={`projPictureArea${shiftedIdx}`}
          layoutId={`projPictureArea${shiftedIdx}`}
          transition={{ type: 'spring', duration: 0.6, bounce: 0.3 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Element name={`scrollAnchor${shiftedIdx}`}></Element>
          <motion.img
            src={item.image}
            style={{
              objectFit: 'cover',
              width: '100%',
              height: '100%',
              borderRadius: '10px',
              position: 'relative',
              zIndex: -10,
            }}
            // initial={{ opacity: 0 }}
            // animate={{ opacity: 1 }}
            // exit={{ opacity: 0 }}
            layout
            key={`projPictureImg${shiftedIdx}`}
            layoutId={`projPictureImg${shiftedIdx}`}
            transition={{ type: 'spring', duration: 0.6, bounce: 0.3 }}
          ></motion.img>
        </motion.div>

        <DarkCover
          transition={{ type: 'spring', duration: 0.6, bounce: 0.3 }}
          layoutId={`projTitle${shiftedIdx}`}
          key={`projTitle${shiftedIdx}`}
          layout
          onClick={() => {
            setPrevScrollPos(window.pageYOffset);
            // console.log(`shiftedidx ${shiftedIdx}`)
            history.push(`/${shiftedIdx}`);
            setPage(shiftedIdx);
            executeScrollUp();
          }}
          style={{
            gridArea: currentGridAreaFunction(i, caseOffset),
            backgroundColor: isMobile ? 'rgba(0,0,0,0.75)' : 'rgba(0,0,0,0.69)',
          }}
        >
          <ItemTitleText>{item.title}</ItemTitleText>
        </DarkCover>
      </>
    );
  });
  // console.log(props.projects[page]?.content);
  // console.log(props.casestudies)
  // console.log(props.casestudies[page - (props.projects.length - 1) - 1]?.content);
  // console.log(page - (props.projects.length - 1) - 1)
  // console.log(props.projects.length)
  // console.log(page)
  // console.log(`page ${page}`);
  return page < 0 ? (
    <ModalGridArea>
      <H2
        variants={itemVar}
        initial="hidden"
        animate="show"
        exit="hidden"
        style={{ gridArea: '1 / 1 / 3 / 10' }}
      >
        Projects
      </H2>
      <H2
        variants={itemVar}
        initial="hidden"
        animate="show"
        exit="hidden"
        style={{
          gridArea: `${itemOffset + 2} / 1 / ${itemOffset + 4} / 10`,
          verticalAlign: 'text-bottom',
        }}
      >
        Spidr Case Studies
      </H2>
      {projectItems}
      {caseItems}
    </ModalGridArea>
  ) : (
    <ModalGridArea>
      <motion.div
        style={{
          gridArea: isMobile ? '1 / 1 / 6 / 14' : `1 / 1 / 3 / 14`,
        }}
        layout
        key={`projPictureArea${page}`}
        layoutId={`projPictureArea${page}`}
        transition={{ type: 'spring', duration: 0.6, bounce: 0.3 }}
      >
        <motion.img
          src={
            props.projects[page]?.image ||
            props.casestudies[page - (props.projects.length - 1) - 1]?.image
          }
          style={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            borderRadius: '10px',
            position: 'relative',
            zIndex: -10,
            overflow: 'hidden',
          }}
          layout
          key={`projPictureImg${page}`}
          layoutId={`projPictureImg${page}`}
          transition={{ type: 'spring', duration: 0.6, bounce: 0.3 }}
        ></motion.img>
      </motion.div>
      <CenterFlex
        style={{
          gridArea: isMobile ? `1/ 1/ 3/ 14` : `1/ 1/ 3/ 14`,
          zIndex: 20,
          cursor: 'pointer',
        }}
        initial={{ opacity: 0, scale: 0.1 }}
        onClick={() => {
          history.push('/');
          executeScroll();
          setPage(-1);
        }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0 }}
        transition={{ type: 'spring', duration: 0.6, bounce: 0.3, delay: 0.3 }}
      ></CenterFlex>
      <DarkCover
        transition={{ type: 'spring', duration: 0.6, bounce: 0.3 }}
        layoutId={`projTitle${page}`}
        key={`projTitle${page}`}
        layout
        onClick={() => {
          history.push('/');
          executeScroll();
          setPage(-1);
        }}
        style={{
          gridArea: isMobile ? '1 / 1 / 6 / 50' : `1 / 1 / 3 / 14`,
          backgroundColor: isMobile ? 'rgba(0,0,0,0.84)' : 'rgba(0,0,0,0.69)',
        }}
      >
        <CenterFlex>
          <ItemTitleText
            style={{
              fontSize: isMobile ? '24px' : '48px',
              display: 'flex',
              alignSelf: 'enter',
              width: '90%',
              margin: 'auto',
            }}
          >
            <BackArrow
              key={'backarrow'}
              style={{
                width: isMobile ? '32px' : '64px',
                height: isMobile ? '32px' : '64px',
                color: 'white',
                marginRight: isMobile ? '15px' : '2rem',
                zIndex: 20,
              }}
            ></BackArrow>{' '}
            {props.projects[page]?.title ||
              props.casestudies[page - (props.projects.length - 1) - 1]?.title}
          </ItemTitleText>
        </CenterFlex>
      </DarkCover>
      <MainContent
        transition={{ type: 'spring', duration: 0.6, bounce: 0.3 }}
        layout
        layoutId={`content${page}`}
        style={{
          gridArea: isMobile ? '6 / 1 / 65 / 14' : `3 / 1 / 24 / 14`,
          marginTop: '-10px',
          zIndex: 15,
        }}
      >
        <MainContentText>
          <ReactMarkdown
            escapeHtml={false}
            renderers={renderers}
            source={
              props.projects[page]?.content ||
              props.casestudies[page - (props.projects.length - 1) - 1]?.content
            }
          />
        </MainContentText>
      </MainContent>
    </ModalGridArea>
  );
};
interface Props {
  projects: modalItem[];
  casestudies: modalItem[];
  init: number;
}
interface emptyProps {}

//Todo: use react-scroll to animate scroll position
//split up into pages
//create blog ui

function App() {

 
  return (
    <>
      <Container>
        <TopOfSite />
        <Element name="scrollAnchorTop"></Element>
      </Container>
      <ModalArea />
    </>
  );
}

export default App;
